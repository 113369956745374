import React from "react";
import loadable from "@loadable/component";
import useDelayedRender from "@hooks/useDelayedRender";
import healthcareBackground from "@images/industries/healthcare/healthcare-background.png";
import {
  CASE_STUDIES,
  WHY_CHOOSE_ITEMS,
  HEADER_BUTTON_NAVIGATION_URL,
  HEADER_TITLE,
  HEADER_DESCRIPTION,
  HEADER_BUTTON_TEXT,
  MAIN_OFFERS_PRESENTATION_SECTION_TITLE,
  MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION,
  MAIN_OFFERS_PRESENTATION_SECTION_ITEMS,
  CALL_TO_SECTION_TITLE,
  CALL_TO_SECTION_BUTTON_TEXT,
  CALL_TO_SECTION_BUTTON_URL,
  MAIN_OFFERS_PRESENTATION_WITH_IMAGE_TITLE,
  MAIN_OFFERS_PRESENTATION_WITH_IMAGE_ITEMS,
  FEEDBACK_SECTION_ITEMS,
  FAQ_SECTION_ITEMS,
} from "./constants";

const PageHeader = loadable(() => import("@components/Landing/components/PageHeader/PageHeader"));

const CenteredContainer = loadable(() => import("@common/CenteredContainer"));

const MainOffersPresentationSection = loadable(() => import("@components/Landing/components/MainOffersPresentationSection/MainOffersPresentationSection"));

const CallToActionSection = loadable(() => import("@components/Landing/components/CallToAction/CallToActionSection"));

const MainOffersPresentationSectionWithImage = loadable(() => import("@components/Landing/components/MainOffersPresentationSectionWithImages/MainOffersPresentationSectionWithImages"));

const CaseStudySection = loadable(() => import("@components/Landing/components/CasesStudySection/CasesStudySection"));

const BenefitsFlippableListSection = loadable(() => import("@common/CommonSections/BenefitsFlippableListSection"));

const FeedbackSection = loadable(() => import("@common/SlideShow/ContentWithFeedback/FeedbacksSection/FeedbacksSection"));

const ProudSection = loadable(() => import("@components/Landing/components/ProudSectionWrapper/ProudSectionWrapper"));

const OfferSection = loadable(() => import("@components/Landing/components/OfferSectionWrapper/OfferSectionWrapper"));

const FAQSection = loadable(() => import("@components/Landing/components/FAQSection/FAQSection"));

const HealthCare = () => {
  const { isDelayed } = useDelayedRender(100);
  return (
    <>
      <PageHeader
        headerButtonNavigation={HEADER_BUTTON_NAVIGATION_URL}
        headerDescription={HEADER_DESCRIPTION}
        headerTitle={HEADER_TITLE}
        headerButtonText={HEADER_BUTTON_TEXT}
        headerMainImage={healthcareBackground}
      />
      {isDelayed && (
        <CenteredContainer>
          <MainOffersPresentationSection
            title={MAIN_OFFERS_PRESENTATION_SECTION_TITLE}
            description={MAIN_OFFERS_PRESENTATION_SECTION_DESCRIPTION}
            isHeadingCentered
            offers={MAIN_OFFERS_PRESENTATION_SECTION_ITEMS}
          />
          <CallToActionSection
            text={CALL_TO_SECTION_TITLE}
            buttonText={CALL_TO_SECTION_BUTTON_TEXT}
            buttonNavigation={CALL_TO_SECTION_BUTTON_URL}
          />
          <MainOffersPresentationSectionWithImage
            title={MAIN_OFFERS_PRESENTATION_WITH_IMAGE_TITLE}
            offers={MAIN_OFFERS_PRESENTATION_WITH_IMAGE_ITEMS}
          />
          <CaseStudySection nodes={CASE_STUDIES} cases="hcsg, carbon" />
          <BenefitsFlippableListSection configuration={WHY_CHOOSE_ITEMS} />
          <FeedbackSection
            feedbacks={FEEDBACK_SECTION_ITEMS}
            title="Our Clients say"
          />
          <ProudSection />
          <OfferSection />
          <FAQSection items={FAQ_SECTION_ITEMS} />
        </CenteredContainer>
      )}
    </>
  )
};

export default HealthCare;

